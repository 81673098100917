/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useRef, useState } from "react"
import PlayIcon from "@medaica/common/components/icons/play-icon"
import PauseIcon from "@medaica/common/components/icons/pause-icon"
import StopIcon from "@medaica/common/components/icons/stop-icon"
import { getConfigNumber, logError, toMMSS } from "@medaica/common/services/util"
import ZoomInIcon from "@medaica/common/components/icons/zoomin-icon"
import ZoomOutIcon from "@medaica/common/components/icons/zoomout-icon"
import { DownloadIcon } from "@heroicons/react/solid"
import JsFileDownloader from "js-file-downloader"
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded"
import VolumeDownRounded from "@mui/icons-material/VolumeDownRounded"
import LoopIcon from "@mui/icons-material/Loop"
import { Slider } from "@mui/material"
import getBlobDuration from "get-blob-duration"
import Regions, { Region } from "wavesurfer.js/dist/plugins/regions.js"
import Timeline from "wavesurfer.js/dist/plugins/timeline.js"
import WaveSurfer from "wavesurfer.js"
import { NoiseQualityRecord } from "./noise-quality-recorder"
import { useTheme } from "@mui/styles"
import { WAFFilterType } from "@medaica/common/components/audio-player/web-audio-filters"
import RegionIcon from "@mui/icons-material/DataArray"
import "@medaica/common/common.css"
import { AuscultationPointType, DiagnosisResult } from "@medaica/common/const"
import { processWavFile } from "@medaica/common/components/audio-player/audioUtils"

interface FilterFileContext extends EventTarget {
  dispose: () => void
  setSource: (audioData: ArrayBuffer | Blob, audioElement: HTMLAudioElement) => Promise<void>
  currentTime: number
  setVolume: (volume: number) => void
  pause: () => void
  play: (isWavesurfer: boolean) => void
  stop: () => void
  volume: number
  apply: (presetFile: string | null) => void
  setDefaultFilterValues: () => void
  setFilter: (lowValue: number | null, highValue: number | null) => void
  toggleAudioEnhancement: (value: boolean) => void
  currentFilterType: WAFFilterType
}

const Progress = ({ progress }: { progress: number | null | undefined }): ReactElement => {
  return <>{toMMSS(progress)}</>
}

type RemoteAudioFile = {
  filename: string
  duration: number
  getData(downloadProgressCallback?: (downloadProgress: number) => void): Promise<Blob>
}

type AudioFile = {
  filename: string
  data: Blob
}

const isRemoteAudioFile = (audioFile: AudioFile | RemoteAudioFile): audioFile is RemoteAudioFile => {
  return "getData" in audioFile
}

type AudioPlayerState = "idle" | "downloading" | "playing"

const AudioPlayer = ({
  audioFile,
  date,
  filterContext,
  noiseQualityRecord,
  onStateChanged,
  useWavesurfer = true,
  showVolumeControl = true,
  onFileDownloaded,
  filtersChangedEventData,
  showCustomFilters = false,
  heartrateBpm = null,
  heartbeatPresent = null,
  heartbeatNormal = null,
  ausculthingDiagnosis = null,
  auscultationPointType = null,
}: {
  audioFile: RemoteAudioFile | AudioFile | null
  date?: number | string | Date
  filterContext: FilterFileContext
  noiseQualityRecord?: NoiseQualityRecord | null
  onStateChanged?: (newState: AudioPlayerState) => void
  useWavesurfer?: boolean
  showVolumeControl?: boolean
  onFileDownloaded?: () => void
  filtersChangedEventData?: string
  showCustomFilters?: boolean
  heartrateBpm?: number | null
  heartbeatPresent?: boolean | null
  heartbeatNormal?: boolean | null
  ausculthingDiagnosis?: number | null
  auscultationPointType?: AuscultationPointType | null
}): ReactElement => {
  const [duration, setDuration] = useState(0)
  const [playbackTime, setPlaybackTime] = useState(0)
  const [playbackProgress, setPlaybackProgress] = useState(0)
  const progressTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [data, setData] = useState<Blob>()
  const [state, setState] = useState<AudioPlayerState>("idle")
  const [playbackControlsDisabled, setPlaybackControlsDisabled] = useState<boolean>(true)
  const [downloadProgress, setDownloadProgress] = useState(0.0)

  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null)
  const [regions, setRegions] = useState<Regions | null>(null)
  const regionId = useRef<string>("")
  const audioPlayerHTMLAudioElementRef = useRef<HTMLAudioElement>(new Audio())
  const wavesurferContainerRef = useRef<HTMLDivElement>(null)
  const [wavesurferReady, setWavesurferReady] = useState(false)
  const [loopingAudioState, setLoopingAudioState] = useState<boolean>(false)

  const [enableRegions, setEnableRegions] = useState<boolean>(false)
  const loopingAudioRef = useRef<boolean>(false)
  const theme = useTheme()
  const [valueFilterParams, setValueFilterParams] = React.useState<number[]>([
    getConfigNumber("WIDE_BAND_FILTER_HIGHPASS_FREQUENCY"),
    getConfigNumber("WIDE_BAND_FILTER_LOWPASS_FREQUENCY"),
  ])
  const [stepFilterParams, setStepFilterParams] = useState(50) // Initial step value
  const marksFilterParams = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 250,
      label: "250Hz",
    },
    {
      value: 500,
      label: "500Hz",
    },
    {
      value: 1000,
      label: "1000Hz",
    },
    {
      value: 1500,
      label: "1500Hz",
    },
    {
      value: 2000,
      label: "2000Hz",
    },
  ]
  const marksVolumeControl = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 20,
      label: "1x",
    },
    {
      value: 40,
      label: "2x",
    },
    {
      value: 60,
      label: "3x",
    },
    {
      value: 80,
      label: "4x",
    },
    {
      value: 100,
      label: "5x",
    },
  ]

  const zoomLevelMin = useRef<number>(0)
  const zoomLevelMax = useRef<number>(0)
  const zoomContainerRef = useRef<HTMLDivElement>(null)

  const [isHiddenViewVisible, setIsHiddenViewVisible] = useState(false)

  const handleHiddenViewShow = () => {
    setIsHiddenViewVisible(!isHiddenViewVisible)
  }

  const handleFilterParamsSliderChange = (_event: Event, newValue: number | number[]) => {
    // newValue is an array of two values [leftValue, rightValue]
    const [leftValue, rightValue] = newValue as number[]
    if (leftValue > rightValue) {
      return
    }
    // Determine the slider step based on the current frequency
    // We want to adjust more precisely when the frequency is lower
    // if thumb is moved to the left of 250Hz, step is 5Hz
    // if thumb is moved to the right of 250Hz, step is 50Hz
    const calcStep = (value: number) => {
      return value <= 250 ? 5 : 50
    }

    if (leftValue !== valueFilterParams[0]) {
      // Left thumb moved
      setValueFilterParams([leftValue, valueFilterParams[1]])
      setStepFilterParams(calcStep(leftValue))
      filterContext.setFilter(leftValue, null)
    } else if (rightValue !== valueFilterParams[1]) {
      // Right thumb moved
      setValueFilterParams([valueFilterParams[0], rightValue])
      setStepFilterParams(calcStep(rightValue))
      filterContext.setFilter(null, rightValue)
    }
  }

  useEffect(() => {
    // On filter type change, reset the slider values to the default values
    const filterParamsMap = {
      [WAFFilterType.wideband]: ["WIDE_BAND_FILTER_HIGHPASS_FREQUENCY", "WIDE_BAND_FILTER_LOWPASS_FREQUENCY"],
      [WAFFilterType.lowband]: ["LOW_BAND_FILTER_HIGHPASS_FREQUENCY", "LOW_BAND_FILTER_LOWPASS_FREQUENCY"],
      [WAFFilterType.highband]: ["HIGH_BAND_FILTER_HIGHPASS_FREQUENCY", "HIGH_BAND_FILTER_LOWPASS_FREQUENCY"],
    }

    const filterParams: string[] = filterParamsMap[filtersChangedEventData?.toString() ?? ""]
    if (filterParams) {
      const lowValue = getConfigNumber(filterParams[0])
      const highValue = getConfigNumber(filterParams[1])
      if (lowValue <= highValue) {
        setValueFilterParams([lowValue, highValue])
      }
    }
  }, [filtersChangedEventData])

  useEffect(() => {
    const fetchData = async () => {
      if (!audioFile) {
        return
      }
      if (isRemoteAudioFile(audioFile)) {
        setDuration(audioFile.duration)
      } else {
        const duration = await getBlobDuration(audioFile.data)
        setDuration(duration)
      }
    }
    fetchData().catch(logError)
  }, [audioFile])

  useEffect(() => {
    if (!filterContext) {
      return
    }
    filterContext.addEventListener("ended", () => {
      if (!useWavesurfer) {
        setPlaybackTime(0)
      } else {
        wavesurfer?.setTime(0)
        audioPlayerHTMLAudioElementRef.current.currentTime = 0
      }
      setState("idle")
    })
    return () => {
      filterContext.dispose()
    }
  }, [filterContext, wavesurfer, useWavesurfer])

  useEffect(() => {
    if (onStateChanged) {
      onStateChanged(state)
    }
  }, [onStateChanged, state])

  useEffect(() => {
    return () => {
      filterContext.dispose()
      if (onStateChanged) {
        onStateChanged("idle")
      }
    }
  }, [filterContext, onStateChanged])

  const download = async () => {
    if (!audioFile) {
      throw new Error("Audio file not set")
    }

    const loadedData = data ?? (await loadData(audioFile))

    void new JsFileDownloader({
      url: URL.createObjectURL(loadedData),
      filename: audioFile.filename,
    })
  }

  const loadData = async (audioFile: AudioFile | RemoteAudioFile): Promise<Blob> => {
    let data: Blob
    if (isRemoteAudioFile(audioFile)) {
      setState("downloading")
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      data = await audioFile.getData((percentDownloaded) => {
        setDownloadProgress(percentDownloaded)
      })
      setState("idle")
    } else {
      data = audioFile.data
    }
    data = new Blob([data], { type: "audio/wav" })
    setPlaybackControlsDisabled(false)
    setDuration(await getBlobDuration(data))
    onFileDownloaded?.()
    await filterContext.setSource(data, audioPlayerHTMLAudioElementRef.current)
    if (audioPlayerHTMLAudioElementRef.current) {
      audioPlayerHTMLAudioElementRef.current.volume = 1
      audioPlayerHTMLAudioElementRef.current.muted = false
    }
    if (auscultationPointType === AuscultationPointType.heart) {
      // bandpass filter for heart sounds, used for visualization
      // in the PCG view (waveform)
      // If the processed data finished with errors,
      // the original data will be returned.
      data = await processWavFile(data)
    }
    setData(data)

    return data
  }

  useEffect(() => {
    const handleFilterPlayback = () => {
      filterContext.apply(filterContext.currentFilterType)
      if (!useWavesurfer) {
        handleNonWavesurferPlayback()
      } else {
        handleWavesurferPlayback()
      }
    }

    const handleNonWavesurferPlayback = () => {
      progressTimeoutRef.current = setInterval(() => {
        const playbackTime = Math.min(filterContext.currentTime, duration)
        setPlaybackTime(playbackTime)
        setPlaybackProgress(Math.floor((playbackTime / duration) * 100))
      }, 100)
      filterContext.play(useWavesurfer)
    }

    const cleanup = () => {
      if (progressTimeoutRef.current) {
        clearInterval(progressTimeoutRef.current)
      }
    }

    const handleWavesurferPlayback = () => {
      if (wavesurfer && wavesurferReady && !wavesurfer.isPlaying()) {
        if (enableRegions) {
          regions?.getRegions().forEach((region) => {
            if (isCurrentRegion(region.id)) {
              region.play()
              void audioPlayerHTMLAudioElementRef.current.play()
            }
          })
        } else {
          void audioPlayerHTMLAudioElementRef.current.play()
          wavesurfer.play().catch(() => {
            logError(this)
            wavesurfer.stop()
          })
        }
      }
    }

    const handleIdleState = () => {
      if (!useWavesurfer) {
        filterContext.pause()
      } else {
        wavesurfer?.pause()
        void audioPlayerHTMLAudioElementRef.current.pause()
      }
    }

    if (state === "playing") {
      handleFilterPlayback()
    } else if (state === "idle") {
      handleIdleState()
    }
    return cleanup()
  }, [state, wavesurfer, wavesurferReady, filterContext, duration, useWavesurfer, enableRegions, regions])

  const handleVolumeChanged = (volume: number) => {
    filterContext.setVolume(volume)
  }

  const handleZoomLevelChanged = (zoomLevel: number) => {
    if (wavesurfer && wavesurferReady) {
      wavesurfer.zoom(zoomLevel * 10 - 1)
    }
  }

  const handlePauseButtonClicked = () => {
    audioPlayerHTMLAudioElementRef.current.pause()
    setState("idle")
  }

  const handlePlayButtonClicked = async () => {
    // Avoid double-clicking on the play button while downloading
    if (state === "downloading") {
      return
    }

    if (!audioFile) {
      throw new Error("Audio file not set")
    }

    if (!data) {
      await loadData(audioFile)
    }

    setState("playing")
  }

  const handleStopButtonClicked = (): void => {
    if (!data) {
      return
    }

    if (!useWavesurfer) {
      filterContext.stop()
    } else {
      wavesurfer?.setTime(0)
      void audioPlayerHTMLAudioElementRef.current.pause()
      audioPlayerHTMLAudioElementRef.current.currentTime = 0
    }
    setState("idle")
  }

  const configureRegionOutEvents = (): void => {
    regions?.unAll()
    const handleRegionOut = (region: Region) => {
      if (isCurrentRegion(region.id) && wavesurfer?.isPlaying()) {
        if (loopingAudioRef.current) {
          region.play()
        } else {
          wavesurfer?.stop()
          void audioPlayerHTMLAudioElementRef.current.pause()
          audioPlayerHTMLAudioElementRef.current.currentTime = 0
          setState("idle")
        }
      }
    }
    regions?.on("region-out", handleRegionOut)
  }

  const handleLoopingButtonClicked = (): void => {
    // Toggle the audio looping state
    setLoopingAudioState((prevLoopingAudio) => !prevLoopingAudio)
    loopingAudioRef.current = !loopingAudioRef.current
    if (enableRegions) {
      configureRegionOutEvents()
    }
  }

  const isCurrentRegion = (id: string): boolean => {
    return id === regionId.current
  }

  const handleRegionButtonClicked = (): void => {
    if (enableRegions) {
      regions?.getRegions().forEach((region) => {
        if (isCurrentRegion(region.id)) {
          region.remove()
        }
      })
      setEnableRegions(false)
      regions?.unAll()
    } else {
      const newRegion = regions?.addRegion({
        start: 1,
        end: duration - 1,
        color: "rgba(0, 255, 0, 0.3)",
        drag: true,
        resize: true,
      })
      if (newRegion) {
        regionId.current = newRegion.id
      }
      setEnableRegions(true)
      configureRegionOutEvents()
    }
  }

  const customRenderFunction = (channels: Array<Float32Array | number[]>, ctx: CanvasRenderingContext2D) => {
    if (channels.length === 0) {
      return
    }
    const channel = channels[0]
    const length = channel.length
    const { canvas } = ctx
    const { height, width } = canvas
    const halfHeight = height / 2
    const hScale = width / length
    ctx.strokeStyle = ctx.fillStyle
    ctx.lineWidth = 1

    ctx.moveTo(0, halfHeight)
    ctx.beginPath()

    let prevX = 0
    let prevY = halfHeight
    let max = 0

    for (const value of channel) {
      const absValue = Math.abs(value)
      if (absValue > max) {
        max = absValue
      }
    }

    for (let i = 0; i < length; i++) {
      const x = Math.round(i * hScale)
      const value = channel[i]

      const y = halfHeight - (value * halfHeight) / max

      ctx.lineTo(prevX, prevY)
      ctx.lineTo(x, y)

      prevX = x
      prevY = y
    }

    ctx.lineTo(prevX, halfHeight)
    ctx.stroke()
    ctx.closePath()
  }

  useEffect(() => {
    if (!wavesurferContainerRef.current) {
      return
    }

    const wavesurfer = WaveSurfer.create({
      container: wavesurferContainerRef.current,
      waveColor: theme.palette.primary.main,
      progressColor: "#6c727f",
      interact: true,
      cursorWidth: 2,
      autoCenter: true,
      autoScroll: true,
      cursorColor: "#f44336",
      fillParent: false,
      normalize: true,
      height: 100,

      dragToSeek: true,
      plugins: [
        Timeline.create({
          height: 15,
          timeInterval: 0.2,
          primaryLabelInterval: 1,
          style: {
            fontSize: "10px",
          },
        }),
      ],
      renderFunction: customRenderFunction,
    })
    wavesurfer.setMuted(true)
    setWavesurfer(wavesurfer)
    const regionsPlugin = wavesurfer.registerPlugin(Regions.create())
    setRegions(regionsPlugin)
  }, [theme.palette.primary.main])

  useEffect(() => {
    if (!wavesurfer || !data) {
      return
    }

    wavesurfer.on("ready", function () {
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      zoomLevelMin.current = zoomContainerRef.current!.offsetWidth / wavesurfer.getDuration() / 10
      zoomLevelMax.current = zoomLevelMin.current * 10

      wavesurfer.setOptions({
        minPxPerSec: zoomLevelMax.current - 1,
      })

      setWavesurferReady(true)

      if (noiseQualityRecord) {
        const increment = noiseQualityRecord.unit / 1000
        noiseQualityRecord.record.forEach((silent, index) => {
          if (silent) {
            regions?.addRegion({
              start: index * increment,
              end: index * increment + increment,
              color: "rgba(255, 0, 0, 0.1)",
              drag: false,
              resize: false,
            })
          }
        })
      }
    })
    wavesurfer.on("finish", function () {
      if (loopingAudioRef.current) {
        void wavesurfer.play()
        void audioPlayerHTMLAudioElementRef.current.play()
      } else {
        wavesurfer.stop()
        void audioPlayerHTMLAudioElementRef.current.pause()
        audioPlayerHTMLAudioElementRef.current.currentTime = 0
        setState("idle")
      }
    })

    void wavesurfer.loadBlob(data)
  }, [wavesurfer, data, noiseQualityRecord, regions])

  useEffect(() => {
    return () => {
      if (wavesurfer) {
        wavesurfer.unAll()
        wavesurfer.destroy()
      }
    }
  }, [wavesurfer])

  useEffect(() => {
    if (!wavesurfer) return

    const handleSeek = (progress: number) => {
      const audioElement = audioPlayerHTMLAudioElementRef.current
      if (!audioElement || isNaN(audioElement.duration)) {
        return
      }
      audioElement.currentTime = progress
    }

    wavesurfer.on("seeking", handleSeek)

    return () => {
      wavesurfer.un("seeking", handleSeek)
    }
  }, [wavesurfer])

  const signalLabel = () => {
    switch (ausculthingDiagnosis) {
      case DiagnosisResult.ERROR:
        return "Error"
      case DiagnosisResult.INSUFFICIENT_DATA:
        return "Unreliable"
      case DiagnosisResult.NORMAL:
      case DiagnosisResult.ABNORMAL:
        return "Reliable"
      default:
        return "-"
    }
  }

  const heartSoundsLabel = () => {
    switch (ausculthingDiagnosis) {
      case DiagnosisResult.NORMAL:
        return "Normal"
      case DiagnosisResult.ABNORMAL:
        return "Abnormal"
      default:
        return "-"
    }
  }

  return (
    <div className="flex flex-col" style={{ maxWidth: "760px" }} ref={zoomContainerRef}>
      <div className="flex flex-row  items-center space-x-2 w-3/5 mt-2 mx-auto">
        <ZoomOutIcon className="text-gray-500" />
        <Slider
          disabled={playbackControlsDisabled}
          aria-label="Zoom"
          size="small"
          defaultValue={zoomLevelMin.current}
          min={zoomLevelMin.current}
          max={zoomLevelMax.current}
          onChange={(_, zoomLevel) => handleZoomLevelChanged(zoomLevel as number)}
          sx={{
            "& .MuiSlider-track": {
              height: ".32em",
            },
            "& .MuiSlider-rail": {
              height: ".32em",
            },
            "& .MuiSlider-thumb": {
              height: "1.1em",
              width: "1.1em",
            },
          }}
        />
        <ZoomInIcon className="text-gray-500" />
      </div>
      {date && (
        <div className="mb-2 text-sm">
          {new Date(date).toLocaleDateString()} {new Date(date).toLocaleTimeString()}
        </div>
      )}
      <div className="mt-4 mb-3" style={{ display: data ? "block" : "none" }}>
        <div ref={wavesurferContainerRef} className="panel bg-white" style={{ display: data ? "block" : "none" }}>
          <audio ref={audioPlayerHTMLAudioElementRef} controls={false} />
        </div>
      </div>
      <div className="flex flex-col">
        {state === "downloading" && (
          <div className="mb-1 mt-5">
            <div className="bg-gray-300 h-2">
              <div className="bg-primary-500 h-full rounded-r-full" style={{ width: `${downloadProgress}%` }} />
            </div>
            <div className="text-sm text-center">Downloading</div>
          </div>
        )}
        {!useWavesurfer && (
          <div className="flex items-center">
            <div className="text-sm">
              <Progress progress={playbackTime} />
            </div>
            <div className="flex-grow bg-gray-300 mx-1 h-2 cursor-pointer">
              <div className="bg-primary-500 h-full" style={{ width: `${playbackProgress}%` }} />
            </div>
            <div className="text-sm">{toMMSS(duration)}</div>
          </div>
        )}
        <div className="mt-3 flex flex-row justify-center space-x-1">
          <button
            title="Stop"
            className="h-10 w-10 tracking-tight button-primary rounded-full flex items-center justify-center"
            disabled={playbackControlsDisabled}
            onClick={() => handleStopButtonClicked()}
          >
            <StopIcon className="text-white h-6" />
          </button>

          {state !== "playing" ? (
            <button
              title="Play"
              className="h-10 w-10 tracking-tight button-primary rounded-full flex items-center justify-center"
              disabled={!audioFile}
              onClick={() => void handlePlayButtonClicked()}
            >
              <PlayIcon className="text-white h-6" style={{ marginLeft: "2px" }} />
            </button>
          ) : (
            <button
              title="Pause"
              className="h-10 w-10 tracking-tight button-primary rounded-full flex items-center justify-center"
              disabled={!audioFile}
              onClick={() => handlePauseButtonClicked()}
            >
              <PauseIcon className="text-white h-6 stroke-1" />
            </button>
          )}
          <button
            title={`Looping ${loopingAudioState ? "On" : "Off"}`}
            className={`h-10 w-10 tracking-tight rounded-full flex items-center justify-center ${
              loopingAudioState ? "bg-blue-300" : "button-primary"
            }`}
            disabled={playbackControlsDisabled}
            onClick={handleLoopingButtonClicked}
          >
            <LoopIcon className="text-white h-6 stroke-1" style={{ color: loopingAudioState ? "blue" : "white" }} />
          </button>
          <button
            title={`Region ${enableRegions ? "On" : "Off"}`}
            className={`h-10 w-10 tracking-tight rounded-full flex items-center justify-center ${
              enableRegions ? "bg-blue-300" : "button-primary"
            }`}
            disabled={playbackControlsDisabled}
            onClick={handleRegionButtonClicked}
          >
            <RegionIcon className="text-white h-6 stroke-1" style={{ color: enableRegions ? "blue" : "white" }} />
          </button>
          <button
            title="Download"
            className="h-10 w-10 tracking-tight button-primary rounded-full flex items-center justify-center"
            onClick={() => void download()}
          >
            <DownloadIcon className="text-white h-6 stroke-1" />
          </button>
        </div>
        {showVolumeControl && (
          <div className="mt-4 flex flex-row items-center space-x-4 w-3/5 mx-auto">
            <VolumeDownRounded className="text-gray-500" style={{ transform: "translateY(-10px)" }} />
            <Slider
              disabled={playbackControlsDisabled}
              aria-label="Volume"
              size="small"
              defaultValue={filterContext.volume}
              min={0}
              max={100}
              step={10}
              marks={marksVolumeControl}
              onChange={(_, volume) => handleVolumeChanged(volume as number)}
              sx={{
                "& .MuiSlider-track": {
                  height: ".32em",
                },
                "& .MuiSlider-rail": {
                  height: ".32em",
                },
                "& .MuiSlider-thumb": {
                  height: "1.1em",
                  width: "1.1em",
                },
              }}
            />
            <VolumeUpRounded className="text-gray-500" style={{ transform: "translateY(-10px)" }} />
          </div>
        )}
        {showVolumeControl &&
          showCustomFilters &&
          filtersChangedEventData !== null &&
          filtersChangedEventData !== WAFFilterType.raw && (
            <div
              className="container items-center w-5/5 mt-2 mx-auto"
              style={{ position: "relative", marginTop: "50px" }}
            >
              <div className="header">
                <h1 style={{ textAlign: "center" }}>
                  Custom filter settings - [ {valueFilterParams[0]}, {valueFilterParams[1]} ] Hz
                </h1>
              </div>
              <div
                className="flex flex-row items-center space-x-2 w-5/5 mt-2 mx-auto"
                style={{ position: "relative", marginTop: "25px" }}
              >
                <Slider
                  disabled={playbackControlsDisabled}
                  getAriaLabel={() => "Frequency range"}
                  value={valueFilterParams}
                  min={0}
                  max={2000}
                  step={stepFilterParams}
                  valueLabelDisplay="auto"
                  size="small"
                  marks={marksFilterParams}
                  onChange={handleFilterParamsSliderChange}
                  disableSwap
                  valueLabelFormat={(value) => `${value} Hz`}
                  sx={{
                    "& .MuiSlider-track": {
                      height: ".32em",
                    },
                    "& .MuiSlider-rail": {
                      height: ".32em",
                    },
                    "& .MuiSlider-thumb": {
                      height: "1.1em",
                      width: "1.1em",
                    },
                  }}
                />
                <div
                  className="heart-rate-container"
                  style={{ marginLeft: "50px" }}
                  onDoubleClick={handleHiddenViewShow}
                >
                  <div className="heart-rate-value" style={{ userSelect: "none" }}>
                    {heartrateBpm !== null && 0 < heartrateBpm && heartrateBpm < 200 ? heartrateBpm : "--"}
                  </div>
                  <div className="heart-rate-label" style={{ userSelect: "none" }}>
                    bpm
                  </div>
                </div>
                <div>
                  <div>
                    <b style={{ whiteSpace: "nowrap" }}>Signal</b>
                  </div>
                  <div>{heartbeatPresent !== null ? (heartbeatPresent ? "Reliable" : "Unreliable") : "-"}</div>
                  <div style={{ height: "3px" }}></div>
                  <div>
                    <b style={{ whiteSpace: "nowrap" }}>Heart sounds</b>
                  </div>
                  <div>
                    {heartbeatPresent === null || heartbeatNormal === null
                      ? "-"
                      : heartbeatPresent
                      ? heartbeatNormal
                        ? "Normal"
                        : "Abnormal"
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          )}
        {showVolumeControl &&
          showCustomFilters &&
          isHiddenViewVisible &&
          filtersChangedEventData !== null &&
          filtersChangedEventData !== WAFFilterType.raw && (
            <div className="mt-4 w-5/5 mx-auto" style={{ marginLeft: "10px", marginTop: "25px" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <div>
                  <b style={{ whiteSpace: "nowrap" }}>Signal</b>
                </div>
                <div>{signalLabel()}</div>
                <div style={{ height: "3px" }}></div>
                {/* This empty div adds spacing between rows */}
                <div>
                  <b style={{ whiteSpace: "nowrap" }}>Heart sounds</b>
                </div>
                <div>{heartSoundsLabel()}</div>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}

export default AudioPlayer
export type { AudioPlayerState, RemoteAudioFile, AudioFile, FilterFileContext }
