/*
 * Copyright © 2024 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import logger from "@medaica/common/services/logging"

// This function processes a WAV file to extract the waveform data, do a bandpass filtering,
// and return WAV as a Blob.
async function processWavFile(data: Blob): Promise<Blob> {
  try {
    const arrayBuffer = await data.arrayBuffer()

    // AudioContext operates at the sample rate of the audio hardware or defaults to 44100 Hz.
    // Therefore, audioBuffer.sampleRate may not match the original sample rate of the WAV file.
    // For waveform visualization in PCG, this discrepancy doesn't affect us.
    // If we need the exact sample rate, we can extract it from the WAV header (bytes 24-27).
    const audioContext = new AudioContext()

    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)

    const sampleRate = audioBuffer.sampleRate // May be different from the original WAV sample rate
    const length = audioBuffer.length // Frame count

    // Extract only the left channel (channel 0)
    const leftChannelData = audioBuffer.getChannelData(0)

    const monoAudioBuffer = new AudioBuffer({
      length: length,
      numberOfChannels: 1,
      sampleRate: sampleRate,
    })

    // Copy the left channel data
    monoAudioBuffer.copyToChannel(leftChannelData, 0)

    await audioContext.close()

    const offlineContext = new OfflineAudioContext(1, length, sampleRate)

    const source = offlineContext.createBufferSource()
    source.buffer = monoAudioBuffer

    const bandpassFilter = offlineContext.createBiquadFilter()
    bandpassFilter.type = "bandpass"
    // Center of the band (35Hz to 200Hz)
    bandpassFilter.frequency.value = 117.5
    // Quality factor for bandwidth, and the filter to
    // isolate frequencies around 117.5 Hz.
    // Bandwidth of 1/3 octaves, the Q = 4.31.
    // Higher Q values will result in a narrower bandpass.
    // BW = Fc / Q -> BW = 117.5 / 4.31 = 27.3Hz
    // Here is passed between ~104Hz and ~131Hz.
    bandpassFilter.Q.value = 4.31

    // Connect the source to the filter, then to the offline context destination
    source.connect(bandpassFilter)
    bandpassFilter.connect(offlineContext.destination)

    source.start()

    const processedBuffer = await offlineContext.startRendering()

    return audioBufferToWavBlob(processedBuffer)
  } catch (error) {
    logger.error("Error during audio processing for PCG waveform:", error)
    // if there is an error, return the original data
    return data
  }
}

function audioBufferToWavBlob(audioBuffer: AudioBuffer): Blob {
  const numberOfChannels = audioBuffer.numberOfChannels
  const length = audioBuffer.length * numberOfChannels * 2 + 44
  const buffer = new ArrayBuffer(length)
  const view = new DataView(buffer)

  writeWavHeader(view, audioBuffer)

  let offset = 44
  for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
    const channelData = audioBuffer.getChannelData(i)
    for (const sample of channelData) {
      view.setInt16(offset, sample * 0x7fff, true)
      offset += 2
    }
  }

  return new Blob([view], { type: "audio/wav" })
}

function writeWavHeader(view: DataView, audioBuffer: AudioBuffer) {
  const channels = audioBuffer.numberOfChannels
  const sampleRate = audioBuffer.sampleRate
  const bitsPerSample = 16

  // RIFF chunk descriptor
  view.setUint32(0, 1380533830, false) // 'RIFF'
  view.setUint32(4, 36 + audioBuffer.length * channels * 2, true) // File size minus this header
  view.setUint32(8, 1463899717, false) // 'WAVE'

  // FMT sub-chunk
  view.setUint32(12, 1718449184, false) // 'fmt '
  view.setUint32(16, 16, true) // Subchunk1 size
  view.setUint16(20, 1, true) // Audio format (PCM)
  view.setUint16(22, channels, true) // Number of channels
  view.setUint32(24, sampleRate, true) // Sample rate
  view.setUint32(28, sampleRate * channels * 2, true) // Byte rate
  view.setUint16(32, channels * 2, true) // Block align
  view.setUint16(34, bitsPerSample, true) // Bits per sample

  // Data sub-chunk
  view.setUint32(36, 1684108385, false) // 'data'
  view.setUint32(40, audioBuffer.length * channels * 2, true) // Data size
}

export { processWavFile }
